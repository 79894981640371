export const WORDS = [
  'trait',
  'ovule',
  'event',
  'nerve',
  'biome',
  'polyp',
  'assay',
  'virus',
  'chyme',
  'human',
  'toxin',
  'fetus',
  'plant',
  'pools',
  'codon',
  'guide',
  'drugs',
  'point',
  'probe',
  'vitro',
  'trial',
  'indel',
  'frame',
  'shift',
  'urnov',
  'write',
  'motif',
  'tenet',
  'oligo',
  'nobel',
  'ipscs',
  'alpha',
  'prize',
  'genus',
  'serum',
  'model',
  'locus',
  'graph',
  'hosts',
  'blood',
  'lines',
  'sepal',
  'nadph',
  'yeast',
  'osteo',
  'genes',
  'ecoli',
  'acids',
  'clade',
  'lytic',
  'clone',
  'germs',
  'exons',
  'pairs',
  'shrub',
  'tcell',
  'exons',
  'gland',
  'edits',
  'risks',
  'viral',
  'micro',
  'bcell',
  'lipid',
  'xylem',
  'grnas',
  'sgrna',
  'liver',
  'ovary',
  'gonad',
  'krebs',
  'lysis',
  'phage',
  'motor',
  'cilia',
  'amino',
  'fluid',
  'codes',
  'scale',
  'batch',
  'stoma',
  'helix',
  'muton',
  'lymph',
  'bases',
  'actin',
  'cycle',
  'krebs',
  'rnase',
  'organ',
  'fungi',
  'cells',
  'dicot',
  'brain',
  'snrnp',
  'prion',
  'heart',
  'inari',
  'spain',
  'deoxy',
  'tales',
  'break',
  'mthfr',
  'flank',
  'folds',
  'jinek',
  'joins',
  'knife',
  'dsdna',
  'asgct',
  'tools',
  'civic',
  'adapt',
  'patch',
  'binds',
  'hauer',
  'zhang',
  'adeno',
  'radar',
  'treat',
  'bound',
  'paris',
  'nicks',
  'stats',
  'cures',
  'snurp',
  'isscr',
  'broad',
  'short',
  'erase',
  'crops',
  'mouse',
  'tides',
  'inter',
  'refer',
  'sites',
  'drive',
  'tsang',
  'qasim',
  'tracr',
  'cargo',
  'algae',
  'agbio',
  'array',
  'chain',
  'prime',
  'spcas',
  'vegfa',
  'knock',
  'islet',
  'crrna',
  'marsh',
  'renew',
  'donor',
  'kayak',
  'level',
  'lenti',
  'tumor',
  'talen',
  'match',
]
