import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to Play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Search for 5 targets and identify the hidden sequence in 6 tries. The
        color of the tiles will evolve to show how close your guess was to the
        word.
      </p>

      <div className="flex justify-center mt-4 mb-1">
        <Cell value="S" status="correct" />
        <Cell value="G" />
        <Cell value="R" />
        <Cell value="N" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter S is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mt-4 mb-1">
        <Cell value="A" />
        <Cell value="M" />
        <Cell value="I" status="present" />
        <Cell value="N" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter I is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mt-4 mb-1">
        <Cell value="H" />
        <Cell value="E" />
        <Cell value="L" />
        <Cell value="I" />
        <Cell value="X" status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter X is not in the word in any spot.
      </p>
    </BaseModal>
  )
}
