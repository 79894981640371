import { BaseModal } from './BaseModal'
import Logo from './Logo'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="mb-4 text-gray-800 max-w-prose dark:text-gray-300">
        It’s like Wordle, but edited for CRISPR engineers.
      </p>
      <p className="mb-4 text-gray-800 dark:text-gray-300">
        Search for 5 targets and identify the hidden sequence in 6 tries. The
        color of the tiles will evolve to show how close your guess was to the
        word.
      </p>
      <p className="mb-4 text-gray-800 dark:text-gray-300">Repeat daily.</p>
      <p className="pt-6 text-sm text-gray-600 border-t dark:text-gray-500">
        From your friends at:
        <a
          href="https://www.synthego.com"
          target="_blank"
          rel="noopener noreferrer"
          className="block mx-24 mt-4 mb-8"
        >
          <Logo />
        </a>{' '}
      </p>
      <p className="block pt-4 text-xs text-gray-500 border-t dark:text-gray-300">
        Forked from the{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          rel="noopener noreferrer"
          className="font-bold underline"
        >
          open source project
        </a>
      </p>
    </BaseModal>
  )
}
